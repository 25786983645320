import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import { useInView } from "react-intersection-observer";
import review1 from "../../Assets/Icons/review-1.png";
import review3 from "../../Assets/Icons/review-3.png";
import review2 from "../../Assets/Icons/review-2.png";
import review11 from "../../Assets/Icons/review-1-1.png";
import review12 from "../../Assets/Icons/review-1-2.webp";
import review13 from "../../Assets/Icons/review-1-3.png";

const Testimonials = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const testimonialData = [
    {
      review:
        "The unique blend of advisory and execution is very promising and cost effective for me. Thank you team formatiqs for finding the best fit solution that is long term and sustained through my team for seamless delivery of operations.",
      imgSrc: review1,
      name: "Denise Turner",
      designation: "Director",
      company: "Newell, USA",
      logoSrc: review11,
    },
    {
      review:
        "I never realised change management is so very important in driving revenues. Thanks for aligning the entire team to our common goal and helping us solve the disconnects through your design thinking workshop. Appreciate the efforts and focus that you bring in your work. All the best.",
      imgSrc: review2,
      name: "Amelia",
      designation: "Product Manager",
      company: "DIAGEO, UK",
      logoSrc: review13,
    },
    {
      review:
        "Your approach is very grounded when you call yourself as Business Optimisation Mechanics. It actually helps us open up and take the outside in perspective from your team. Thank you for helping us setting up our HR organisation and ensuring it runs like a well oiled machine. You are truly the meachnics for our business. Great going team formatiqs.",
      imgSrc: review3,
      name: "Kashish Lewis",
      designation: "Chief Executive Officer",
      company: "PrepEdge, IND",
      logoSrc: review12,
    },
  ];

  const testimonials = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    customPaging: function (i, active) {
      return (
        <div
          className={`custom-dot ${active ? "active-dot" : ""}`}
          key={i}
        ></div>
      );
    },
  };

  return (
    <>
      <style>
        {`
          .card-testimonial-content {
            max-width: 100%;
            margin: 0 auto;
            height: 500px;
            max-height: 100%;
          }
        `}
      </style>

      <div
        ref={ref}
        className={`page-wrapper ${
          inView ? "animate__animated animate__fadeInUp" : ""
        }`}
      >
        <div className="container">
          <div className="title-testimonial">
            <h2>Trusted by Global OEM Customers</h2>
          </div>

          <div className="card-testimonial">
            <Slider {...testimonials}>
              {testimonialData.map((testimonial, index) => (
                <div className="card-testimonial-content" key={index}>
                  <div className="content-testimonials">
                    <div className="review">
                      <p>{testimonial.review}</p>
                    </div>
                    <div className="all-content">
                      <div className="icons-name">
                        <div className="img-testimonial">
                          <img src={testimonial.imgSrc} alt="review" />
                        </div>
                        <div className="name-testimonial">
                          <h6>{testimonial.name}</h6>
                          <span>{` / ${testimonial.designation}, ${testimonial.company}`}</span>
                        </div>
                      </div>
                      <div className="logos">
                        <div className="logo-testimonial">
                          <img src={testimonial.logoSrc} alt="reviewlogo" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
