import React from "react";
import about from "../../Assets/Video/about.mp4";

const PageBanner = ({ pageTitle, pageName }) => {
  return (
    <section className="page-banner">
      <video autoPlay loop muted className="video-about">
        <source src={about} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="page-banner-content text-center">
              <h1 className="page-title">{pageTitle ? pageTitle : pageName}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PageBanner;
