import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

const DataFaq = () => {
  const faqsData = [
    {
      title: "SME Advisory",
      content:
        "Our diverse mix of well established experts from fortune 500 companies are committed to provide tailored consulting. We help build business strategy, financial management, market entry, scalability, along with guidance on compliance matters. We empower SMEs to identify opportunities, mitigate risks, and develop sustainable growth strategies, to thrive in a competitive landscape.",
    },
    {
      title: "Solution Consulting",
      content:
        "We offer our services for specific solutions as per the business needs. Each business has its own unique challenge. We gain a deep understanding of the business in order to identify appropriate solutions and successfully implement them. Organizations looking to improve efficiency, enhance customer experiences, or address specific operational or technological challenges can connect with us.",
    },
    {
      title: "Training",
      content:
        "Our programs empower individuals to excel in their careers and adapt to changing industry demands. We provide industry relevant knowledge about industry trends, emerging technologies, and best practices. We ensure that individuals are equipped with the knowledge and capabilities needed for their roles.",
    },
    {
      title: "Industry Learning",
      content:
        "Industry integrated learning offers the best value in today's competitive world. Our initiatives facilitate collaboration between educational institutions and businesses to ensure that education remains relevant to real-world needs. Helping students to develop a structured understanding of industry processes and best practices through industry-specific case studies illustrating challenges and solutions.",
    },
    {
      title: "SME Profiling & Brand Positioning",
      content:
        "Effective brand positioning can attract customers who resonate with the brand's values and offerings. We highlight the strengths and unique offerings of your business to create a distinct and appealing image in the market.",
    },
    {
      title: "Launching soon",
      content: [
        "1. Operations / Process Management",
        "2. Change Management",
        "3. Program Management",
        "4. Data Intelligence",
      ],
    },
  ];
  const [active, setActive] = useState(`event-0`);

  return (
    <>
      <Accordion activeKey={active} className="accordion">
        {faqsData.map((faq, i) => (
          <div
            key={i}
            className={`accordion-card mb-15 ${
              active === `event-${i}` ? "accordion-active" : ""
            }`}
          >
            <div className="card-header" onClick={() => setActive(`event-${i}`)}>
              <Accordion.Item eventKey={`event-${i}`} style={{ cursor: "pointer" }}>
                <div>
                  <h5>{faq.title}</h5>
                </div>
              </Accordion.Item>
            </div>
            <Accordion.Collapse eventKey={`event-${i}`}>
              <div className="card-body">
                {Array.isArray(faq.content) ? (
                  <ul>
                    {faq.content.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p>{faq.content}</p>
                )}
              </div>
            </Accordion.Collapse>
          </div>
        ))}
      </Accordion>
    </>
  );
};
export default DataFaq;
