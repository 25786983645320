import React from "react";
import PageBanner from "../Components/About/PageBanner";
import WhoWeAre from "../Components/About/WhoWeAre";
import OurVision from "../Components/About/OurVision";
import TeamStrength from "../Components/About/TeamStrength";
import Videos from "../Components/About/Videos";
import Newsletter from "../Components/Newsletter";
import Innovation from "../Components/Home/Innovation";

const About = () => {
  return (
    <>
      <div className="page-wrapper">
          <PageBanner />
          <WhoWeAre />
          <OurVision />
          <Videos />
          <TeamStrength />
          <Newsletter />
          <Innovation />
      </div>
    </>
  );
};
export default About;
