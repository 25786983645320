import React from "react";
import logo from "../Assets/Logo/logo.png";
import {
  FaLinkedin,
  FaInstagram,
  FaEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const redirectPage = () => {
    navigate("/");
  };

  return (
    <footer className="footer-container">
      <div className="footer-column">
        <img style={{cursor:"pointer"}} onClick={redirectPage} src={logo} alt="Logo" className="footer-logo" />
      </div>
      <div className="footer-column">
        <ul className="useful-links">
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/whatwedo">What We Do</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
      <div className="footer-column">
        <ul className="social-links">
          <li>
            <Link to="/terms">Term & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy & Policy</Link>
          </li>
        </ul>
      </div>
      <div className="footer-column">
        <ul className="social-links">
          <li>
            <a
              href="tel:+918296778009"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaPhoneAlt /> +91 82967 78009
            </a>
          </li>
          <li>
            <a
              href="mailto:coe@formatiqs.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaEnvelope /> coe@formatiqs.in
            </a>
          </li>
          <li>
            <a
              href="/https://www.linkedin.com/company/formatiqs-training/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaLinkedin /> LinkedIn
            </a>
          </li>
          <li>
            <a
              href="/https://www.instagram.com/formatiqs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaInstagram /> Instagram
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
