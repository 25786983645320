import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "animate.css";
import { useInView } from "react-intersection-observer";

const testimonialData = [
  {
    title: "Soft Skills for Students",
    content:
      "In an ever changing world, tomorrow leaders must navigate a complex landscape of challenges and opportunities. We train future leaders to be better equipped when facing the most relevant issues of our times. Our innovative techniques to teach and reinforce soft skills, ensure that students not only understand the concepts but also know how to apply them effectively in real-world scenarios. Soft skills are the driving force behind effective communication, collaboration, and leadership. Our approach to soft skill training covers a wide range of competencies that students will find invaluable in their careers. Nurturing an effective conflict resolution approach with empathy, flexibility and constructive communication makes sure that the individuals can lead diverse teams and take critical decisions.",
  },
  {
    title: "Soft Skills for Employees",
    content:
      "Soft skills go beyond personal attributes. It is equally important as technical skills. Soft skills enhancement for professionals not only increases team efficiency but also fosters a healthy workplace culture. Teams with consistent soft skill training are better equipped than only technically skilled teams. It helps them build the mindset to face challenges head on, learn from new experiences and be better equipped to handle change. Employees with clear and empathetic communication create a harmonious and efficient work environment. Fostering teams and individuals with a collaborative approach is beneficial for the overall performance of the organization. The ability to communicate with others gracefully and navigate the workplace with poise boosts, their confidence and professionalism.",
  },
  {
    title: "IKIGAI Enablement",
    content:
      "Ikigai is a Japanese concept where ‘Iki’ means ‘life’ and ‘Gai’ means ‘value’ or ‘worth’. Aligning with their Ikigai helps individuals center on what they excel at and what they have expertise in. It helps them harness the power of soft skills and excel in their respective domains. Leadership will be focused and heading to the same common goals. Ikigai is more than a concept. It is a way of life, just like Hinduism. Ikigai is a Japanese concept where ‘Iki’ means ‘life’ and ‘Gai’ means ‘value’ or ‘worth’. It encapsulates the essence of finding meaning and purpose in life. Aligning with Ikigai helps individuals center on what they excel at and what they have expertise in. In the context of soft skills, Ikigai guides individuals to identify what they are truly passionate about and where their expertise lies. It helps find the intersection between personal fulfillment and contributing to the organization at the same time. This unified pursuit of excellence not only fosters personal growth but also propels the organization toward its goals.",
  },
  {
    title: "Faculty Training & Workshops",
    content:
      "Our programs are also made to train faculty members so they can deliver their best. Integration of interactive technology in teaching can equip faculty with the skills needed to engage students effectively. We facilitate workshops for faculty members to collaborate on specific projects or teaching modules. This promotes teamwork and helps educators appreciate the diverse strengths within the team. Interdisciplinary efforts foster cross-pollination of ideas and lead to innovative teaching methods.Faculty can benefit from workshops that focus on curriculum alignment, in line with best current practices in education. Empowering faculty with improved teaching methods and specialized training improves the overall success of the educational institution as well. Utilizing interactive tools, digital platforms, and online resources can help impart knowledge effectively with a more student centered approach.",
  },
  {
    title: "Leadership Workshop",
    content:
      "While technical competence is undeniably essential, the true hallmark of a great leader is their proficiency in soft skills. We train individuals to effectively solve problems and take charge in today’s complex business world. Leadership is not solely defined by one&apos;s technical knowledge or positional authority. It is characterized by the ability to influence, inspire, and guide others toward shared goals. Our programs focus on cultivating the art of problem-solving. Leaders who excel in soft skills have the ability to approach complex challenges with a creative and strategic mindset. They can dissect issues, generate innovative solutions, and make informed decisions that drive organizational success. We make sure our programs support the learning of the soft skills that empower your role in the organization.",
  },
];

const Announcements = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const CustomLArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
        <span>
          <i className="fa-solid fa-arrow-left" />
        </span>
      </div>
    );
  };

  const CustomRArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-next-arrow" onClick={onClick}>
        <span>
          <i className="fa-solid fa-arrow-right" />
        </span>
      </div>
    );
  };

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 200,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomLArrow />,
    nextArrow: <CustomRArrow />,
  };

  return (
    <>
      <section
        ref={ref}
        className={`${inView ? "animate__animated animate__fadeInUp" : ""}`}
      >
        <div className="container announcement-section">
          <div className="about-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-content-box content-box-gap">
                  <div className="title">
                    <h2> Insights | Perspectives | Announcements</h2>
                  </div>
                  <Slider
                    {...sliderSettings}
                    className="testimonial-slider-three wow fadeInUp mt-50"
                  >
                    {testimonialData.map((testimonial, index) => (
                      <div className="testimonial-item-five" key={index}>
                        <div className="row align-items-lg-center">
                          <div className="p-3">
                            <div className="card-announcement testimonial-content p-3 my-3">
                              <div className="author-title">
                                <h4>{testimonial.title}</h4>
                              </div>
                              <p>
                                {testimonial.content.length > 280
                                  ? `${testimonial.content.substring(
                                      0,
                                      280
                                    )}...`
                                  : testimonial.content}
                              </p>
                              {testimonial.content.length > 280 && (
                                <Link to="/about-us">
                                  <button className="btn-turning-vision">
                                    Know More
                                  </button>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Announcements;
