import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HMM from "../../Assets/Hero/HMM.png";
import HMMM from "../../Assets/Hero/HMMM.png";
import HMM1 from "../../Assets/Hero/HMM-1.jpg";
import HMM2 from "../../Assets/Hero/HMM-2.jpg";
import HMM3 from "../../Assets/Hero/HMM-3.jpg";
import HMM4 from "../../Assets/Hero/HMM-4.jpg";
import { Link } from "react-router-dom";

const slideData = [
  { id: 1, imageSrc: HMMM, alt: "hmmm" },
  { id: 2, imageSrc: HMM, alt: "hmm" },
  { id: 3, imageSrc: HMM1, alt: "hmm1" },
  { id: 4, imageSrc: HMM2, alt: "hmm2" },
  { id: 5, imageSrc: HMM3, alt: "hmm3" },
  { id: 6, imageSrc: HMM4, alt: "hmm4" },
];

const FourCards = () => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    customPaging: function (i, active) {
      return (
        <div
          className={`custom-dot ${active ? "active-dot" : ""}`}
          key={i}
        ></div>
      );
    },
  };

  return (
    <>
      <div className="page-container">
        <div className="title mb-5">
          <h2>Current Offerings</h2>
        </div>
        <div className="container">
          <Slider {...sliderSettings} className="gallery-slider mb-20">
            {slideData.map((slide) => (
              <center key={slide.id}>
                <div className="hmm-cards-slider">
                  <div className="imageHMM">
                    <img src={slide.imageSrc} alt={slide.alt} />
                  </div>
                </div>
              </center>
            ))}
          </Slider>
        </div>
        <div className="card-grid">
          <BlogCard
            title="Essential Skill Training"
            imageSrc="https://img.freepik.com/free-photo/copy-space-badge-label-stamp-banner-concept_53876-15820.jpg?w=826&t=st=1705504309~exp=1705504909~hmac=ffc9bacf957c885ce2095a6d008a1ee93c5efdb65963c8f2c125a67f3340450b"
            content="Essential skills training typically refers to the development of fundamental skills that are crucial for personal and professional success. These skills are often considered foundational and are applicable across various domains and industries."
          />
          <BlogCard
            title="University Excellence - HMM 360"
            imageSrc="https://img.freepik.com/free-photo/hirshhorn_181624-35465.jpg?w=900&t=st=1705504638~exp=1705505238~hmac=8ec18d66b01c08864f47c9ea130648885f0b13210ef1566d543898a2cec6f0f9"
            content="University Excellence is broad and can refer to various aspects of a university's performance, achievements, and commitment to high standards across multiple areas."
          />
          <BlogCard
            title="Design think workshop for leaders"
            imageSrc="https://img.freepik.com/free-photo/leadership-teamwork-management-support-strategy-concept_53876-13723.jpg?w=996&t=st=1705504735~exp=1705505335~hmac=5f93f53e4b94ee032b975d1bcb9878eceae92d82f2a78a75e0447e021a6f8985"
            content="A Design Thinking Workshop for Leaders is a program aimed at teaching leaders and decision-makers how to apply design thinking principles to problem-solving and innovation within their organizations. Design thinking is a human-centered approach to solving complex problems, fostering creativity, and driving innovation. It encourages a deep understanding of users, promotes collaboration, and emphasizes iterative prototyping and testing."
          />
          <BlogCard
            title="PMO as a services"
            imageSrc="https://img.freepik.com/free-vector/infographic-dashboard-user-panel_52683-30026.jpg?w=900&t=st=1705504955~exp=1705505555~hmac=c8f546621bf8fbbf8ec6e5bad79e64273ca711ca9c17e0911f3f770a00e4201e"
            content="
          A Program Management Office (PMO) is a centralized management structure within an organization that is responsible for defining and maintaining project management and program management standards and practices. The primary purpose of a PMO is to ensure that projects and programs align with the strategic objectives of the organization and are executed efficiently and effectively."
          />
        </div>
      </div>
    </>
  );
};

class BlogCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { flipped: false };
    this.flip = this.flip.bind(this);
  }

  flip = () => {
    this.setState({ flipped: !this.state.flipped });
  };

  render() {
    const { title, imageSrc, content } = this.props;

    return (
      <div
        onMouseEnter={this.flip}
        onMouseLeave={this.flip}
        className={`card-container${this.state.flipped ? " flipped" : ""}`}
      >
        <Front title={title} content={content} imageSrc={imageSrc} />
        <Back content={content} />
      </div>
    );
  }
}

const Front = ({ title, imageSrc, content }) => {
  return (
    <div className="front">
      <ImageArea title={title} imageSrc={imageSrc} />
      <MainArea title={title} content={content} />
    </div>
  );
};

const Back = ({ content }) => {
  return (
    <div className="back">
      <p>{content}</p>
      <Link to="/">Read More...</Link>
    </div>
  );
};

const ImageArea = ({ title, imageSrc, index }) => {
  const colors = ["blue", "orange"];

  const colorClass = colors[index % colors.length];

  return (
    <div className={`image-container ${colorClass}`}>
      <img className="card-image" src={imageSrc} alt={`${title} cover`} />
      <h1 className="title">{title}</h1>
    </div>
  );
};

const MainArea = ({ title, content }) => {
  const truncateContent = (text, limit) => {
    if (text) {
      const words = text.split(" ");
      return words.length > limit
        ? words.slice(0, limit).join(" ") + "..."
        : text;
    }
    return "";
  };

  return (
    <div className="main-area">
      <div className="blog-post">
        <p className="blog-content">
          <hr />
          {truncateContent(content, 12)}
        </p>
      </div>
    </div>
  );
};

export default FourCards;
