import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForPartners() {
  const form = useRef();
  const [isLinkedInChecked, setLinkedInChecked] = useState(false);

  const handleLinkedInChange = (e) => {
    setLinkedInChecked(e.target.checked);
  };

  const [formData, setFormData] = useState({
    name: "",
    partner_profile: null,
    email: "",
    tel: "",
    industry_1: null,
    industry_2: null,
    client_segment: null,
    engagement: null,
    current_status: null,
    profile_share: null,
    specify_partner_profile: "",
    specify_industry_1: "",
    specify_client_segment: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    partner_profile: false,
    email: false,
    tel: false,
    industry_1: false,
    industry_2: false,
    client_segment: false,
    engagement: false,
    more_details: false,
    current_status: false,
    profile_share: false,
    specify_partner_profile: false,
    specify_industry_1: false,
    specify_client_segment: false,
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    if (
      selectedOption &&
      selectedOption.value === "Others" &&
      name !== "partner_profile" &&
      name !== "industry_1" &&
      name !== "client_segment"
    ) {
      setFormData({
        ...formData,
        [name]: selectedOption,
        specify_partner_profile: "",
        specify_industry_1: "",
        specify_client_segment: "",
      });
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
      if (name === "industry_1") {
        setFormData({
          ...formData,
          industry_2: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: selectedOption,
      });
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
    }

    if (name === "industry_1" && selectedOption) {
      const selectedIndustry = selectedOption.value;
      const updatedIndustryTwoOptions = industryTwo.filter((option) =>
        option.label.includes(selectedIndustry)
      );
      setIndustryTwoOptions(updatedIndustryTwoOptions);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_1a1m5yk",
        "template_gdjqbo7",
        form.current,
        "-3D_um43d5oi3zCTk"
      )
      .then(
        (result) => {
          toast.success("Form submitted successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setFormData({
            name: "",
            partner_profile: null,
            email: "",
            tel: "",
            industry_1: null,
            industry_2: null,
            client_segment: null,
            engagement: null,
            current_status: null,
            profile_share: null,
            specify_partner_profile: "",
            specify_industry_1: "",
            specify_client_segment: "",
            more_details: "",
          });
        },
        (error) => {
          toast.error("Form submission failed!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const PartnerProfile = [
    { value: "Trainer", label: "Trainer" },
    { value: "Domain Expert", label: "Domain Expert" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Business Consultant", label: "Business Consultant" },
    { value: "Fresher", label: "Fresher" },
    { value: "Intern", label: "Intern" },
    { value: "Others", label: "Others" },
  ];

  const industryOne = [
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Services", label: "Services" },
    { value: "Education", label: "Education" },
    { value: "Technology", label: "Technology" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Financial Services", label: "Financial Services" },
    { value: "Tourism", label: "Tourism" },
    { value: "Telecom", label: "Telecom" },
    { value: "Renewable Energy", label: "Renewable Energy" },
    { value: "Infrastructure", label: "Infrastructure" },
    { value: "Others", label: "Others" },
  ];

  const [industryTwoOptions, setIndustryTwoOptions] = useState([]);

  const industryTwo = [
    { value: "Higher Education", label: "Higher Education" },
    {
      value: "Automotive & Auto Components Manufacturing",
      label: "Automotive & Auto Components Manufacturing",
    },
    {
      value: "Chemicals & Petrochemicals Manufacturing",
      label: "Chemicals & Petrochemicals Manufacturing",
    },
    {
      value: "Textiles & Apparel Manufacturing",
      label: "Textiles & Apparel Manufacturing",
    },
    {
      value: "Electronics & IT Hardware Manufacturing",
      label: "Electronics & IT Hardware Manufacturing",
    },
    {
      value: "Pharmaceuticals Manufacturing",
      label: "Pharmaceuticals Manufacturing",
    },
    {
      value: "Roads & Highway Infrastructure",
      label: "Roads & Highway Infrastructure",
    },
    {
      value: "Ports & Shipping Infrastructure",
      label: "Ports & Shipping Infrastructure",
    },
    { value: "Railways Infrastructure", label: "Railways Infrastructure" },
    { value: "Urban Infrastructure", label: "Urban Infrastructure" },
    {
      value: "Solar Power Renewable Energy",
      label: "Solar Power Renewable Energy",
    },
    {
      value: "Wind Power Renewable Energy",
      label: "Wind Power Renewable Energy",
    },
    { value: "Bio Renewable Energy", label: "Bio Renewable Energy" },
    {
      value: "Information Technology (IT)",
      label: "Information Technology (IT)",
    },
    {
      value: "Business Process Outsourcing Technology (BPO)",
      label: "Business Process Outsourcing Technology (BPO)",
    },
    {
      value: "Software Development Technology",
      label: "Software Development Technology",
    },
    { value: "IT Consulting Technology", label: "IT Consulting Technology" },
    {
      value: "Outsourcing Services Technology",
      label: "Outsourcing Services Technology",
    },
    {
      value: "Telecom Services Telecommunication",
      label: "Telecom Services Telecommunication",
    },
    {
      value: "Equipment Manufacturing Telecommunication",
      label: "Equipment Manufacturing Telecommunication",
    },
    {
      value: "Pharmaceuticals Healthcare",
      label: "Pharmaceuticals Healthcare",
    },
    {
      value: "Medical Devices Healthcare",
      label: "Medical Devices Healthcare",
    },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Hotels Resorts", label: "Hotels Resorts" },
    { value: "Tourism Infrastructure", label: "Tourism Infrastructure" },
    { value: "Banking & Finance", label: "Banking & Finance" },
    { value: "Insurance", label: "Insurance" },
    { value: "Vocational Training", label: "Vocational Training" },
    { value: "Food Processing", label: "Food Processing" },
    { value: "Agribusiness", label: "Agribusiness" },
  ];

  const clientSegment = [
    { value: "Startup", label: "Startup" },
    { value: "ScaleUp", label: "Scale Up" },
    { value: "MSME", label: "MSME" },
    { value: "Family Business", label: "Family Business" },
    { value: "College", label: "College" },
    { value: "University", label: "University" },
    { value: "School", label: "School" },
    { value: "Corporate", label: "Corporate" },
    { value: "Individual", label: "Individual" },
    { value: "Others", label: "Others" },
  ];

  const engagement = [
    { value: "Consulting Services", label: "Consulting Services" },
    { value: "Training Delivery", label: "Training Delivery" },
    { value: "Execution Support", label: "Execution Support" },
    { value: "Program Management", label: "Program Management" },
  ];

  const CurrentStatus = [
    { value: "Fulltime Employee", label: "Fulltime Employee" },
    { value: "Contract Employee", label: "Contract Employee" },
    { value: "Freelancer", label: "Freelancer" },
  ];

  const profileShare = [
    { value: "Don't Share", label: "Don't Share" },
    { value: "Share Offline", label: "Share Offline" },
    { value: "Share in Public", label: "Share in Public" },
  ];

  const fees = [
    { value: "Probono (Not Charging)", label: "Probono (Not Charging)" },
    { value: "Charge Through Company", label: "Charge Through Company" },
    { value: "Charges as an Individual", label: "Charges as an Individual" },
    { value: "Lets Talk & Decide", label: "Lets Talk & Decide" },
  ];

  return (
    <>
      <section className="for-client">
        <div className="container">
          <div className="title-for-client">
            <h3>For Partner</h3>
          </div>
          <hr />
          <ToastContainer />
          <form ref={form} onSubmit={sendEmail}>
            <div className="row">
              <div className="col-lg-6 my-3">
                <label>Partners Profile</label>
                <Select
                  name="partner_profile"
                  value={formData.partner_profile}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, {
                      name: "partner_profile",
                    })
                  }
                  options={PartnerProfile}
                  required
                />
                {formErrors.partner_profile && (
                  <div>This field is required</div>
                )}
              </div>
              {formData.partner_profile &&
                formData.partner_profile.value === "Others" && (
                  <div className="col-lg-6 my-3">
                    <label>Specify Partner Profile</label>
                    <input
                      type="text"
                      name="specify_partner_profile"
                      value={formData.specify_partner_profile}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                )}
              <div className="col-lg-6 my-3">
                <label>Full Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                />
                {formErrors.name && <div>This field is required</div>}
              </div>
              <div className="col-lg-6 my-3">
                <label>Email Id</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                />
                {formErrors.email && <div>This field is required</div>}
              </div>
              <div className="col-lg-6 my-3">
                <label>Contact No.</label>
                <input
                  type="tel"
                  name="tel"
                  value={formData.tel}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                />
                {formErrors.tel && <div>This field is required</div>}
              </div>
              <div className="col-lg-6 my-3">
                <label>Industry - Level 1</label>
                <Select
                  name="industry_1"
                  value={formData.industry_1}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, { name: "industry_1" })
                  }
                  options={industryOne}
                  required
                />
                {formErrors.industry_1 && <div>This field is required</div>}
              </div>
              {formData.industry_1 &&
                formData.industry_1.value === "Others" && (
                  <div className="col-lg-6 my-3">
                    <label>Specify Industry - Level 1</label>
                    <input
                      type="text"
                      name="specify_industry_1"
                      value={formData.specify_industry_1}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                )}

              <div className="col-lg-6 my-3">
                <label>Industry - Level 2</label>
                <Select
                  name="industry_2"
                  value={formData.industry_2}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, { name: "industry_2" })
                  }
                  options={industryTwoOptions}
                  required={
                    !formData.industry_1 ||
                    formData.industry_1.value !== "Others"
                  } // Set required based on condition
                  isDisabled={
                    !formData.industry_1 ||
                    formData.industry_1.value === "Others"
                  } // Disable based on condition
                />
                {formErrors.industry_2 && <div>This field is required</div>}
              </div>

              <div className="col-lg-6 my-3">
                <label>Client Segment</label>
                <Select
                  name="client_segment"
                  value={formData.client_segment}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, {
                      name: "client_segment",
                    })
                  }
                  options={clientSegment}
                  required
                />
                {formErrors.client_segment && <div>This field is required</div>}
              </div>
              {formData.client_segment &&
                formData.client_segment.value === "Others" && (
                  <div className="col-lg-6 my-3">
                    <label>Specify Client Segment</label>
                    <input
                      type="text"
                      name="specify_client_segment"
                      value={formData.specify_client_segment}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                )}

              <div className="col-lg-6 my-3">
                <label>Engagement Type</label>
                <Select
                  name="engagement"
                  value={formData.engagement}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, {
                      name: "engagement",
                    })
                  }
                  options={engagement}
                  required
                />
                {formErrors.engagement && <div>This field is required</div>}
              </div>

              <div className="col-lg-6 my-3">
                <label>Provide More Details</label>
                <input
                  className="form-control"
                  name="more_details"
                  value={formData.more_details}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.more_details && <div>This field is required</div>}
              </div>

              <div className="col-lg-6 my-3">
                <label>Current Status</label>
                <Select
                  name="current_status"
                  value={formData.current_status}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, {
                      name: "current_status",
                    })
                  }
                  options={CurrentStatus}
                  required
                />
                {formErrors.current_status && <div>This field is required</div>}
              </div>

              <div className="col-lg-6 my-3">
                <label>Share Profile with Potential Client</label>
                <Select
                  name="profile_share"
                  value={formData.profile_share}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, {
                      name: "profile_share",
                    })
                  }
                  options={profileShare}
                  required
                />
                {formErrors.profile_share && <div>This field is required</div>}
              </div>

              <div className="col-lg-6 my-3">
                <label>Professional Fees</label>
                <Select
                  name="fees"
                  value={formData.fees}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, {
                      name: "fees",
                    })
                  }
                  options={fees}
                  required
                />
                {formErrors.fees && <div>This field is required</div>}
              </div>

              <div className="col-12 my-3">
                <input
                  type="checkbox"
                  className="checkbox-container"
                  name="checkbox"
                  id="checkbox"
                  onChange={handleLinkedInChange}
                />
                &nbsp;&nbsp;
                <label htmlFor="checkbox">LinkedIn Connect (If you wish)</label>
                <div className="linkedIn-icon row">
                  {isLinkedInChecked && (
                    <div className="col-lg-6">
                      <textarea
                        name="linkedIn"
                        id="linkedIn"
                        cols="30"
                        rows="1"
                        placeholder="Provide your LinkedIn Profile Link"
                        style={{ resize: "none" }}
                      ></textarea>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <button
              className="form-button my-3"
              type="submit"
              disabled={loading}
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default ForPartners;
