import React from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import DATAFAQ from "./DATAFAQS";

const FAQS = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <section
        ref={ref}
        className={`${inView ? "animate__animated animate__fadeInUp" : ""}`}
      >
        <div className="container my-5">
          <div className="row">
            <div className="col-12">
              <div className="faq-one_content-box mb-50">
                <div
                  className="faq-accordion-two mb-50 wow fadeInUp"
                  id="accordionOne"
                >
                  <DATAFAQ />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQS;
