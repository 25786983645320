import React, { useState } from "react";
import { Link } from "react-router-dom";
import "animate.css";
import { useInView } from "react-intersection-observer";

const OurVision = () => {
  const [activeTab, setActiveTab] = useState("mission");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <div
        ref={ref}
        className={`${
          inView
            ? "animate__animated animate__fadeInUp our-mission-section"
            : ""
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="about-content-box">
                <div className="tab-content-box wow fadeInUp">
                  <ul className="nav nav-tabs mb-20">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          activeTab === "mission" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("mission")}
                      >
                        Our Mission
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          activeTab === "vision" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("vision")}
                      >
                        Our Vision
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content mb-30">
                    <div
                      className={`tab-pane ${
                        activeTab === "mission" ? "active" : ""
                      }`}
                    >
                      <div className="content-box">
                        <p>
                          " To cultivate a continuous improvement & change ready
                          mindset in MSMEs, Institutions, and family businesses
                          through people, processes, data, experiences, and
                          technology "
                        </p>
                      </div>
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "vision" ? "active" : ""
                      }`}
                    >
                      <div className="content-box">
                        <p>
                          " Pioneering as the top-tier global partner for MSMEs,
                          Institutions & family businesses in driving
                          transformative improvements across people, processes,
                          data, experiences, and technology with a focus on
                          accelerating change adoption "
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurVision;
