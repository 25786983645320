import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactForm() {
  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    company_name: "",
    who_you_are: null,
    looking_for: null,
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    tel: false,
    company_name: false,
    who_you_are: false,
    looking_for: false,
    message: false,
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_1a1m5yk",
        "template_gdjqbo7",
        form.current,
        "-3D_um43d5oi3zCTk"
      )
      .then(
        (result) => {
          toast.success("Form submitted successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setFormData({
            name: "",
            email: "",
            tel: "",
            company_name: "",
            who_you_are: null,
            looking_for: null,
            message: "",
          });
        },
        (error) => {
          toast.error("Form submission failed!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const whoYouAreOptions = [
    { value: "Individual", label: "Individual" },
    { value: "Startup", label: "Startup" },
    { value: "SME", label: "SME" },
    { value: "School", label: "School" },
    { value: "College", label: "College" },
    { value: "Corporate", label: "Corporate" },
    { value: "Other", label: "Other" },
  ];

  const lookingForOptions = [
    {
      value: "Industry Essential Training",
      label: "Industry Essential Training",
    },
    { value: "Industry Update Lectures", label: "Industry Update Lectures" },
    { value: "NAAC & NBA Consulting", label: "NAAC & NBA Consulting" },
    {
      value: "Performance & Project Governance",
      label: "Performance & Project Governance",
    },
    { value: "SME Advisory", label: "SME Advisory" },
    { value: "Decision Making Models", label: "Decision Making Models" },
    {
      value: "Change Management Workshops",
      label: "Change Management Workshops",
    },
    { value: "General Query", label: "General Query" },
  ];

  return (
    <>
      <ToastContainer />
      <form ref={form} onSubmit={sendEmail}>
        <div className="row">
          <div className="col-lg-6 my-2">
            <label>Full Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="form-control"
            />
            {formErrors.name && <div>This field is required</div>}
          </div>
          <div className="col-lg-6 my-2">
            <label>Email Id</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="form-control"
            />
            {formErrors.email && <div>This field is required</div>}
          </div>
          <div className="col-lg-6 my-2">
            <label>Contact No.</label>
            <input
              type="tel"
              name="tel"
              value={formData.tel}
              onChange={handleInputChange}
              required
              className="form-control"
            />
            {formErrors.tel && <div>This field is required</div>}
          </div>
          <div className="col-lg-6 my-2">
            <label>Company Name</label>
            <input
              type="text"
              name="company_name"
              value={formData.company_name}
              onChange={handleInputChange}
              required
              className="form-control"
            />
            {formErrors.company_name && <div>This field is required</div>}
          </div>
          <div className="col-lg-6 my-2">
            <label>Who you are</label>
            <Select
              name="who_you_are"
              value={formData.who_you_are}
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, { name: "who_you_are" })
              }
              options={whoYouAreOptions}
              required
            />
            {formErrors.who_you_are && <div>This field is required</div>}
          </div>
          <div className="col-lg-6 my-2">
            <label>Looking for</label>
            <Select
              name="looking_for"
              value={formData.looking_for}
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, { name: "looking_for" })
              }
              options={lookingForOptions}
              required
            />
            {formErrors.looking_for && <div>This field is required</div>}
          </div>

          <div className="col-12 my-3">
            <label>Message</label>
            <textarea
              className="form-control"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
            {formErrors.message && <div>This field is required</div>}
          </div>
        </div>
        <button className="form-button" type="submit" disabled={loading}>
          Send Message
        </button>
      </form>
    </>
  );
}

export default ContactForm;
