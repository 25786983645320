import React from "react";
import Hero from "../Components/Home/Hero";
import Innovation from "../Components/Home/Innovation";
import Testimonials from "../Components/Home/Testimonials";
import Products from "../Components/Home/Products";
import FourCards from "../Components/Home/FourCards";

const Home = () => {
  return (
    <>
      <Hero />
      <Products />
      <FourCards />
      <Innovation />
      <Testimonials />
    </>
  );
};
export default Home;
