import React, { useEffect } from "react";
import Newsletter from "../Components/Newsletter";
import Innovation from "../Components/Home/Innovation";
import Infographics from "../Components/About/Infographics";
import "animate.css";
import { useInView } from "react-intersection-observer";
import Banner from "../Components/WhatWeDo/Banner";
import FAQS from "../Components/WhatWeDo/FAQS";
import TakeLook from "../Components/Home/TakeLook";

const WhatWeDo = () => {

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    const bubbleList = document.querySelectorAll(".bubble-container");
    const bubbleCount = bubbleList.length;
    const degStep = 180 / (bubbleCount - 1);

    bubbleList.forEach((bubble, index) => {
      const deg = index * degStep;
      const invertDeg = deg * -1;

      bubble.style.transform = `rotate(${deg}deg)`;
      bubble.style.opacity = "1";
      bubble.querySelector(
        ".bubble"
      ).style.transform = `rotate(${invertDeg}deg)`;
    });
  }, []);


  return (
    <>
      <Banner pageName={"What We Do"} />
      <div
        ref={ref}
        className={`${inView ? "animate__animated animate__fadeInUp what-we-do" : ""}`}
      >
        <div className="container">
          <div className="job-details-wrapper">
            <div className="row">
              <div className="col-12">
                <FAQS />
                <TakeLook />
                <Infographics />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Innovation />
    </>
  );
};

export default WhatWeDo;
