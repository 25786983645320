import React from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";

const WhoWeAre = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <div
        ref={ref}
        className={`${
          inView ? "animate__animated animate__fadeInUp whoWe-are-section" : ""
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="card-about">
              <div className="card-content">
                <p>
                  FormatiQs empowers MSMEs, educational institutions, family run
                  businesses and startups to elevate their business operations
                  through its uniquely enabled business optimization mechanics.
                  Business Optimization mechanics is a relevant & holistic
                  approach to continuous improvement where team formatiQs helps
                  in enriching customer experiences by optimizing decision
                  making with data intelligence, enabling business processes
                  maturity with cutting-edge tools, techniques and technologies
                  enhancing people performance for an accelerated & sustainable
                  change adoption with lasting impact.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;
