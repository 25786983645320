import React, { useState } from "react";
import core1 from "../../Assets/Video/core1.mp4";
import core2 from "../../Assets/Video/core2.mp4";
import core3 from "../../Assets/Video/core3.mp4";
import core4 from "../../Assets/Video/core4.mp4";
import core5 from "../../Assets/Video/core5.mp4";
import core6 from "../../Assets/Video/core6.mp4";
import "animate.css";
import { useInView } from "react-intersection-observer";

const Videos = () => {
  const [currentVideo, setCurrentVideo] = useState(0);

  const handleVideoChange = (index) => {
    setCurrentVideo(index);
    if (videoRef.current) {
      videoRef.current.load();
    }
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const videoRef = React.createRef();

  const videoData = [
    {
      src: core1,
      title: "Customer Focus",
    },
    {
      src: core2,
      title: "Tenacity",
    },
    {
      src: core3,
      title: "Inclusive",
    },
    {
      src: core4,
      title: "Innovation",
    },
    {
      src: core5,
      title: "Integrity",
    },
    {
      src: core6,
      title: "Respect for All",
    },
  ];

  return (
    <>
      <div
        ref={ref}
        className={`${
          inView ? "animate__animated animate__fadeInUp about-section" : ""
        }`}
      >
        <div className="container">
          <div className="title my-3">
            <h2>
              Work Ethics &{" "}
              <span style={{ color: "#febe02" }}>Core Values</span>
            </h2>
          </div>
          <div className="video-slider">
            <div className="video-thumbnails mt-5">
              {videoData.map((video, index) => (
                <div
                  key={index}
                  className={`thumbnail mb-4 ${
                    currentVideo === index ? "active" : ""
                  }`}
                  onClick={() => handleVideoChange(index)}
                >
                  {video.title}
                </div>
              ))}
            </div>
            <video
              ref={videoRef}
              src={videoData[currentVideo].src}
              controls
              muted
              autoPlay
              loop
              className="video-player"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Videos;
