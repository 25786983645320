import "./App.css";
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Spin } from 'antd';
import Home from "./Page/Home";
import About from "./Page/About";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import WhatWeDo from "./Page/WhatWeDo";
import Contact from "./Page/Contact";
import ScrollTop from "./Components/ScrollTop";
import Terms from "./Page/Terms";
import Policy from "./Page/Policy";
import KnowMore from "./Page/KnowMore";
import ForClient from "./Page/ForClient";
import ForPartners from "./Page/ForPartners";


function withLoadingSpinner(Component) {
  return function WithLoadingSpinner(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <Spin spinning={loading} size="large">
        <Component {...props} />
      </Spin>
    );
  };
}

const HomeWithLoading = withLoadingSpinner(Home);
const AboutWithLoading = withLoadingSpinner(About);
const WhatWeDoWithLoading = withLoadingSpinner(WhatWeDo);
const ContactWithLoading = withLoadingSpinner(Contact);
const TermsWithLoading = withLoadingSpinner(Terms);
const PolicyWithLoading = withLoadingSpinner(Policy);
const KnowMoreWithLoading = withLoadingSpinner(KnowMore);
const ForClientWithLoading = withLoadingSpinner(ForClient);
const ForPartnersWithLoading = withLoadingSpinner(ForPartners);

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <ScrollTop />
        <Routes>
          <Route path="/" element={<HomeWithLoading />} />
          <Route path="/about" element={<AboutWithLoading />} />
          <Route path="/whatwedo" element={<WhatWeDoWithLoading />} />
          <Route path="/contact" element={<ContactWithLoading />} />
          <Route path="/terms" element={<TermsWithLoading />} />
          <Route path="/privacy" element={<PolicyWithLoading />} />
          <Route path="/knowmore" element={<KnowMoreWithLoading />} />
          <Route path="/client" element={<ForClientWithLoading />} />
          <Route path="/partner" element={<ForPartnersWithLoading />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
