import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Newsletter from "../Components/Newsletter";
import icon2 from "../Assets/Icons/icon-2.png";
import icon3 from "../Assets/Icons/icon-3.png";
import img1 from "../Assets/Hero/img-1.png";
import Banner from "../Components/Contact/Banner";
import "animate.css";
import { useInView } from "react-intersection-observer";

const Contact = () => {
  const form = useRef();

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const subjectOptions = [
    {
      value: "Corporate Experience Center",
      label: "Corporate Experience Center",
    },
    { value: "Industry Guest Lectures", label: "Industry Guest Lectures" },
    {
      value: "Leadership Strategy Workshop",
      label: "Leadership Strategy Workshop",
    },
    { value: "Essential Skills Training", label: "Essential Skills Training" },
    { value: "Design Thinking Workshops", label: "Design Thinking Workshops" },
    { value: "NAAC & NBA Enablement", label: "NAAC & NBA Enablement" },
    { value: "PMO Services", label: "PMO Services" },
    {
      value: "Business Process Optimization",
      label: "Business Process Optimization",
    },
    { value: "Performance Management", label: "Performance Management" },
    { value: "Process Maturity Modeling", label: "Process Maturity Modeling" },
    { value: "Decision Making Models", label: "Decision Making Models" },
    {
      value: "Maturity Measurements & Models",
      label: "Maturity Measurements & Models",
    },
    { value: "Change Adoption Workshop", label: "Change Adoption Workshop" },
    { value: "Operational Excellence", label: "Operational Excellence" },
    {
      value: "Metrics Management & Visualization",
      label: "Metrics Management & Visualization",
    },
    {
      value: "Data Analytics & Intelligence",
      label: "Data Analytics & Intelligence",
    },
    { value: "Quality Assurance", label: "Quality Assurance" },
    { value: "Risk Management", label: "Risk Management" },
    { value: "Training Management", label: "Training Management" },
    { value: "Digitization", label: "Digitization" },
    {
      value: "Technology, Tools & Accelerators",
      label: "Technology, Tools & Accelerators",
    },
  ];

  const engageOptions = [
    { value: "Q&A", label: "Q&A" },
    { value: "Advice", label: "Advice" },
    { value: "Consult", label: "Consult" },
    { value: "Enable", label: "Enable" },
    { value: "Accelerate", label: "Accelerate" },
    { value: "Execute", label: "Execute" },
    { value: "Outsource", label: "Outsource" },
  ];

  const [formData, setFormData] = useState({
    subject: "",
    message: "",
    name: "",
    email: "",
    phone: "",
    checkbox: null,
    engage: "",
  });

  const [formErrors, setFormErrors] = useState({
    subject: false,
    message: false,
    name: false,
    email: false,
    phone: false,
    checkbox: false,
    engage: false,
  });

  const [setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_1a1m5yk",
        "template_5rx9vs8",
        form.current,
        "-3D_um43d5oi3zCTk"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Form submitted successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          setFormData({
            subject: null,
            message: "",
            name: "",
            email: "",
            phone: "",
            checkbox: "",
            engage: null,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error("Form submission failed", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <Banner pageName={"Contact Us"} />
      <section className="contact-information-section">
        <div className="container">
          <div className="row align-items-center mt-5">
            <div className="col-lg-6">
              <div className="information-one_img-box wow fadeInLeft">
                <img src={img1} alt="Contact" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="information-one_content-box ml-lg-40">
                <div className="section-title section-title-left wow fadeInDown">
                  <span className="sub-title">Get In Touch</span>
                  <h2>Ready to Work Together! Join With FormatiQs</h2>
                </div>
                <div className="single-info-item animate-hover-icon d-flex my-2 wow fadeInUp">
                  <div className="icon">
                    <img src={icon2} alt="Icon" />
                  </div>
                  <div className="info">
                    <h3 className="title">Email Us</h3>
                    <p>
                      <a href="mailto:coe@formatiqs.in">coe@formatiqs.in</a>
                    </p>
                  </div>
                </div>
                <div className="single-info-item animate-hover-icon d-flex my-2 wow fadeInUp">
                  <div className="icon">
                    <img src={icon3} alt="Icon" />
                  </div>
                  <div className="info">
                    <h3 className="title">Contact Number</h3>
                    <p>
                      <a href="tel:+918296778009">+91 82967 78009</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-page-map wow fadeInUp my-5">
        <div className="map-box">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31112.294734027735!2d77.68484074162897!3d12.905352575941881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae12e7511b8463%3A0x97cc704f1c3547c3!2sCarmelaram%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1703503803148!5m2!1sen!2sin"
          ></iframe>
        </div>
      </section>

      <section className="contact-section">
        <div className="container">
          <div
            ref={ref}
            className={`${
              inView ? "animate__animated animate__fadeInUp row" : ""
            }`}
          >
            <div className="col-lg-5">
              <div className="row">
                <div className="col-12">
                  <div className="contact-one_content-box wow fadeInLeft">
                    <div className="section-title section-title-left">
                      <span className="sub-title">
                        We would love to chat with you!
                      </span>
                      <h2>Connect with Us!</h2>
                    </div>
                    <hr />
                    <div className="social-box pt-50">
                      <div className="titles">
                        <h2>Follow Us</h2>
                      </div>
                      <ul className="social-links d-flex">
                        <li className="mx-1">
                          <a href="https://www.linkedin.com/company/formatiqs-training/">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                        <li className="mx-1">
                          <a href="https://www.instagram.com/formatiqs/">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="contact-one_form-box ml-lg-40 mb-50 wow fadeInRight">
                <form ref={form} onSubmit={sendEmail} className="contact-form">
                  <div className="row">
                    <div className="col-lg-6 my-2">
                      <label>Full Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="form-control"
                      />
                      {formErrors.name && <div>This field is required</div>}
                    </div>
                    <div className="col-lg-6 my-2">
                      <label>Email Id</label>
                      <input
                        type="email"
                        label="Email Address"
                        variant="outlined"
                        name="email"
                        required
                        className="form-control"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 my-2">
                      <label>Contact No.</label>
                      <input
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        required
                        className="form-control"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-6 my-2">
                      <label>Select Subject</label>
                      <Select
                        name="subject"
                        value={formData.subject}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, {
                            name: "subject",
                          })
                        }
                        options={subjectOptions}
                        required
                      />
                      {formErrors.subject && <div>This field is required</div>}
                    </div>

                    <div className="col-lg-6 my-2">
                      <label>Select engagement Level</label>
                      <Select
                        name="engage"
                        value={formData.engage}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, {
                            name: "engage",
                          })
                        }
                        options={engageOptions}
                        required
                      />
                      {formErrors.engage && <div>This field is required</div>}
                    </div>

                    <div className="col-12 my-2">
                      <label>Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.message && <div>This field is required</div>}
                    </div>
                    <div className="col-12">
                      <label
                        control={
                          <input type="checkbox" name="checkbox" id="check1" />
                        }
                        label={
                          <span>
                            I agree that my data is collected and stored.
                          </span>
                        }
                      />
                    </div>
                    <div className="col-12">
                      <div>
                        <button className="btn-turning-vision" type="submit">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />
    </>
  );
};

export default Contact;
