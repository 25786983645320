import React from "react";

const Terms = () => {
  const termSections = [
    {
      title: "Acceptance",
      content:
        "By accessing or using our website and services, you expressly agree to abide by these Terms & Conditions.",
    },
    {
      title: "Updates",
      content:
        "Please be aware that these terms may be subject to periodic updates. It is your responsibility to regularly review them for any modifications.",
    },
    {
      title: "Legal Use",
      content:
        "You are required to use our services exclusively for lawful and legitimate purposes. Any use that may cause harm to our systems or other users is strictly prohibited.",
    },
    {
      title: "Intellectual Property",
      content:
        "All content featured on our platform is either our proprietary intellectual property or is used under license. Usage for personal, non-commercial purposes is permitted.",
    },
    {
      title: "Termination",
      content:
        "We retain the right to suspend or terminate your access to our services in the event of a breach of these terms.",
    },
    {
      title: "Liability",
      content:
        "Please note that FormatiQs assumes no liability for any damages arising from your use of our website and services. You agree to indemnify and hold us harmless from any claims arising out of your use of our services.",
    },
  ];

  return (
    <>
      <section className="mt-5">
        <div className="container p-5">
          <div className="card-privacy">
            <div className="privacy-page">
              <div className="privacy-header">
                <h3>Terms and Conditions</h3>
                <hr />
                <p>
                  Welcome to FormatiQs! By using our website and services, you
                  agree to comply with these Terms & Conditions:
                </p>
              </div>

              <div className="privacy-content my-3">
                {termSections.map((section, index) => (
                  <div key={index}>
                    <h6 className="my-2">
                      {index + 1}. {section.title}
                    </h6>
                    <p>{section.content}</p>
                    <hr />
                  </div>
                ))}
              </div>

              <div className="privacy-contact">
                <p>
                  For any inquiries or concerns, please contact us at{" "}
                  <a href="mailto:coe@formatiqs.in">coe@formatiqs.in</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
