import React, { useState } from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";

const TeamStrength = () => {
  const [visibleStrengths, setVisibleStrengths] = useState(8);

  const showMore = () => {
    setVisibleStrengths((prevCount) => prevCount + 4);
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const teamStrengths = [
    {
      icon: "fa-bullseye",
      title: "Strategic Governance",
      experience: "20 Years",
    },
    {
      icon: "fa-list-check",
      title: "Program Management",
      experience: "23 Years",
    },
    {
      icon: "fa-bookmark",
      title: "Operational Excellence",
      experience: "18 Years",
    },
    {
      icon: "fa-database",
      title: "Data Intelligence",
      experience: "12 Years",
    },
    {
      icon: "fa-star",
      title: "Quality & Training Management",
      experience: "16 Years",
    },
    {
      icon: "fa-server",
      title: "Digital Transformation",
      experience: "07 Years",
    },
    {
      icon: "fa-dice",
      title: "Change Management",
      experience: "09 Years",
    },
    {
      icon: "fa-user",
      title: "Leadership Workshop",
      experience: "11 Years",
    },
    {
      icon: "fa-business-time",
      title: "Business Process Improvement",
      experience: "19 Years",
    },
    {
      icon: "fa-asterisk",
      title: "Risk Management",
      experience: "13 Years",
    },
    {
      icon: "fa-microchip",
      title: "Technology Management",
      experience: "15 Years",
    },
    {
      icon: "fa-clipboard",
      title: "Product Management",
      experience: "14 Years",
    },
    {
      icon: "fa-building-columns",
      title: "University Excellence",
      experience: "07 Years",
    },
  ];

  return (
    <>
      <div
        ref={ref}
        className={`${
          inView ? "animate__animated animate__fadeInUp team-section" : ""
        }`}
      >
        <div className="container">
          <div className="title my-3">
            <h2>Team Strength</h2>
          </div>
          <div className="row">
            {teamStrengths.slice(0, visibleStrengths).map((strength, index) => (
              <div className="col-lg-3 col-md-12 my-3" key={index}>
                <div className="card-team-strength">
                  <div className="icons-card my-3">
                    <i className={`fa-solid ${strength.icon}`}></i>
                  </div>
                  <div className="title-card my-3">
                    <h2>{strength.title}</h2>
                  </div>
                  <div className="content-card my-3">
                    <p>{strength.experience}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {teamStrengths.length > visibleStrengths && (
            <div className="text-center">
              <button className="btn-turning-vision mb-4" onClick={showMore}>
                Show More
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TeamStrength;
