import React from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";

const Infographics = () => {

    const [ref, inView] = useInView({
        triggerOnce: true,
      });

  return (
    <>
      <div
        ref={ref}
        className={`${
          inView ? "animate__animated animate__fadeInUp infographics-box" : ""
        }`}
      >
        <div className="title">
          <h2>Our Approach</h2>
        </div>

        <div className="main-container centralized">
          <div className="main-circle">
            <div className="inner centralized">
              Client
              <br />
              Solution
            </div>
          </div>
          <div className="bubble-container centralized blue-dark">
            <div className="pointer centralized">
              <div className="arrow"></div>
              <div className="inner"></div>
            </div>
            <div className="bubble centralized">
              <div className="inner centralized">
                <h6>
                  Q & A <span style={{ fontSize: "10px" }}>Mins</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="bubble-container centralized green">
            <div className="pointer centralized">
              <div className="arrow"></div>
              <div className="inner"></div>
            </div>
            <div className="bubble centralized">
              <div className="inner centralized">
                <h6>
                  Advisory <span style={{ fontSize: "10px" }}>Days</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="bubble-container centralized orange">
            <div className="pointer centralized">
              <div className="arrow"></div>
              <div className="inner"></div>
            </div>
            <div className="bubble centralized">
              <div className="inner centralized">
                <h6>
                  Consult <span style={{ fontSize: "10px" }}>Weeks</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="bubble-container centralized red">
            <div className="pointer centralized">
              <div className="arrow"></div>
              <div className="inner"></div>
            </div>
            <div className="bubble centralized">
              <div className="inner centralized">
                <h6>
                  Enable <span style={{ fontSize: "10px" }}>Months</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="bubble-container centralized black">
            <div className="pointer centralized">
              <div className="arrow"></div>
              <div className="inner"></div>
            </div>
            <div className="bubble centralized">
              <div className="inner centralized">
                <h6>
                  Accelerate <span style={{ fontSize: "10px" }}>Quarter</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="bubble-container centralized blue-light">
            <div className="pointer centralized">
              <div className="arrow"></div>
              <div className="inner"></div>
            </div>
            <div className="bubble centralized">
              <div className="inner centralized">
                <h6>
                  Execute <span style={{ fontSize: "10px" }}>Half Yearly</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="bubble-container centralized ocean-light">
            <div className="pointer centralized">
              <div className="arrow"></div>
              <div className="inner"></div>
            </div>
            <div className="bubble centralized">
              <div className="inner centralized">
                <h6>
                  Outsource <span style={{ fontSize: "10px" }}>Annual</span>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="circle-container" id="circle-container">
          <div className="outer-circle">
            <div className="inner-circle">
              <div className="inner-circle-content">
                <h6>
                  Q & A <span style={{ fontSize: "8px" }}>Mins</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="outer-circle">
            <div className="inner-circle">
              <div className="inner-circle-content">
                <h6>
                  Advisory <span style={{ fontSize: "8px" }}>Days</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="outer-circle">
            <div className="inner-circle">
              <div className="inner-circle-content">
                <h6>
                  Consult <span style={{ fontSize: "8px" }}>Weeks</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="outer-circle">
            <div className="inner-circle">
              <div className="inner-circle-content">
                <h6>
                  Enable <span style={{ fontSize: "8px" }}>Months</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="outer-circle">
            <div className="inner-circle">
              <div className="inner-circle-content">
                <h6>
                  Accelerate <span style={{ fontSize: "8px" }}>Quarter</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="outer-circle">
            <div className="inner-circle">
              <div className="inner-circle-content">
                <h6>
                  Execute <span style={{ fontSize: "8px" }}>Half Yearly</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="outer-circle">
            <div className="inner-circle">
              <div className="inner-circle-content">
                <h6>
                  Outsource <span style={{ fontSize: "8px" }}>Annual</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Infographics;
