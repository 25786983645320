import React from "react";
import { Link } from "react-router-dom";

const Banner = ({ pageTitle, pageName }) => {
  return (
    <section className="bg_whatWeDo">
      <div className="overlay"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="page-banner-content text-center">
              <div className="title">
                <h2>{pageTitle ? pageTitle : pageName}</h2>
              </div>
              <ul className="breadcrumb-link">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">/ {pageName}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;