import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import slider1 from "../../Assets/Slider/slider-1.jpg";
import slider2 from "../../Assets/Slider/slider-2.jpg";
import slider3 from "../../Assets/Slider/slider-3.jpg";
import slider4 from "../../Assets/Slider/slider-4.jpg";

const TakeLook = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const projects = [
    {
      title: "Industry Essential Skills (Non Tech)",
      image: slider1,
      content:
        "Enhance your career with our industry-focused non-technical skill training. Master the essential soft skills that are in high demand across various sectors.",
    },
    {
      title: "Industry Update Lectures",
      image: slider2,
      content:
        "Stay ahead of the curve with our industry update lectures. Gain valuable insights and knowledge about the latest trends and developments in your field.",
    },
    {
      title: "NAAC & NBA Consulting",
      image: slider3,
      content:
        "Ensure academic excellence with our NAAC and NBA consulting services. We provide expert guidance and support for institutions seeking accreditation and quality enhancement.",
    },
    {
      title: "Performance & Project Governance",
      image: slider4,
      content:
        "Boost project success rates with our performance and project governance programs. Learn effective governance strategies to drive exceptional outcomes.",
    },
    {
      title: "SME Advisory",
      image: slider1,
      content:
        "Navigate the complexities of small and medium-sized enterprises (SMEs) with our Institute 360-degree advisory. Access comprehensive guidance for SME growth and success.",
    },
    {
      title: "Decision Making Models",
      image: slider2,
      content:
        "Improve decision-making processes through our models for measures, data analysis, report generation, reviews, and action planning. Make informed choices that drive results.",
    },
    {
      title: "Change Management Workshops",
      image: slider3,
      content:
        "Embrace change with confidence through our change management workshops. Equip your team with the skills and strategies needed to.",
    },
  ];

  const [activeProject, setActiveProject] = useState(null);

  const handleProjectClick = (index) => {
    if (activeProject === index) {
      setActiveProject(null);
    } else {
      setActiveProject(index);
    }
  };

  return (
    <>
      <div
        ref={ref}
        className={`${inView ? "animate__animated animate__fadeInUp takeLook" : ""}`}
      >
        <div className="title">
          <h2>Take a Look at What We Do</h2>
        </div>
        <section className="project-section">
          <div className="container mt-5">
            <Slider {...sliderSettings} className="service-slider-one">
              {projects.map((project, index) => (
                <div
                  key={index}
                  className={`service-item wow fadeInUp ${
                    activeProject === index ? " hover" : ""
                  }`}
                  onClick={() => handleProjectClick(index)}
                >
                  <div className="img-holder">
                    <img src={project.image} alt="Project" />
                  </div>
                  <div className="service-content">
                    <div className="text">
                      <div className="title-icon">
                        <h3 className="title">{project.title}</h3>
                      </div>
                    </div>
                    <div className="hover-text">
                      <p>{project.content}</p>
                      <Link to="/what-we-do" className="icon-btn">
                        <i className="fa-solid fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </div>
    </>
  );
};
export default TakeLook;
