import React from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import ContactForm from "./ContactForm";

const Innovation = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <div
        ref={ref}
        className={`${inView ? "animate__animated animate__fadeInUp" : ""}`}
      >
        <div className="title-innovation">
          <h2>We would love to chat with you</h2>
        </div>

        <div className="innovation-vision">
          <div className="innovation-content row">
            <div className="col-lg-4">
              <div className="innovation-card-small">
                <div className="innovation-card-content">
                  <div className="provide-innovation">
                    <h4>Connect with Us!</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="innovation-card-small">
                <div className="innovation-card-content">
                  <div className="provide-innovation-content">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Innovation;
