import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon from "../../Assets/Icons/iconFromatiqs.png";

const Products = () => {

  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sliderPaused, setSliderPaused] = useState(false);

  let sliderRef;

  const openPopup = (item) => {
    setSelectedItem(item);
    setShowPopup(true);
    setSliderPaused(true);
  };

  const closePopup = () => {
    setSelectedItem(null);
    setShowPopup(false);
    setSliderPaused(false);
  };

  useEffect(() => {
    sliderRef && sliderRef.slickPlay();
    if (sliderPaused) {
      sliderRef && sliderRef.slickPause();
    }
  }, [sliderPaused, sliderRef]);

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`customNextIcon ${className}`}
        onClick={onClick}
      />
    );
  };
  
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`customPrevIcon ${className}`}
        onClick={onClick}
      />
    );
  };

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderItems = [
    {
      image: icon,
      title: "UNIVERSITY EXCELLENCE",
      description: "(NAAC & NIRF Enabler)",
      category: "University & Colleges",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "University Excellence is driven by growth, innovation, and high standards. Our expertise in designing structured education, training, and research, along with active governance, prepares universities for future challenges and aids in better Ranking in NAAC and NIRF ratings."
    },
    {
      image: icon,
      title: "LEADERSHIP GOVERNANCE",
      description: "(PMO - B.O.T)",
      category: "Founders & CEOs",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Leadership Governance harmonizes leader roles and governance systems for organizational success. We help in enhancing key elements like engagement, strategic alignment, program management (PMO-B.O.T), adaptability, and risk management."
    },
    {
      image: icon,
      title: "ESSENTIAL SKILLS TRAINING",
      description: "(Future Non-Tech & Tech)",
      category: "Institutes & Schools",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Essential Skills Training in bridging theory and practice for professionals. Our focused programs equip individuals with foundational abilities for succeeding in their roles and enhancing organizational performance and helps focuses on bridging Future Tech & Non-Tech Skills."
    },
    {
      image: icon,
      title: "DESIGN THINKING WORKSHOP",
      description: "(Decision Making Enabler)",
      category: "Leadership & Founders",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Design Thinking is more than a process—it's a mindset fostering curiosity, empathy, and collaboration. We deliver practical workshops to enhance creative problem-solving and enables faster Decision Making."
    },
    {
      image: icon,
      title: "PROCESS ORIENTATION",
      description: "(Build Operate Transfer)",
      category: "Start Ups & MSMEs",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Process Orientation is organizing activities around processes, fostering adaptability, collaboration, and effective results. With our rich expertise, we offer practical guidance in aligning your processes for superior results."
    },
    {
      image: icon,
      title: "METRICS MANAGEMENT",
      description: "(Visualization & Dashboard)",
      category: "Institutes & Scale ups",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Metrics Management focuses on identifying improvement areas, tracking resource costs, and enabling informed decisions. Our expertise in building real-time digital dashboards covering Visualization, empower your organization."
    },
    {
      image: icon,
      title: "OPERATIONALISE DELIVERY",
      description: "(Center Of Excellence)",
      category: "MSMEs & Institutes",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Operational Excellence is a continuous journey toward performance, efficiency, and effectiveness. Our expertise combines purpose, technology, creatin of COEs (Centre of Excellence) helps in continuous improvement for remarkable results."
    },
    {
      image: icon,
      title: "CHANGE MANAGEMENT",
      description: "(Delivery Workshop)",
      category: "Leadership Team",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Change Management is beyond processes—about people, communication, and adaptability and we help organization effectively embrace Change Management which ensures organizations to thieve in an ever-evolving landscape."
    },
    {
      image: icon,
      title: "TOOLS & APPLICATIONS",
      description: "(Implementation & Adoption)",
      category: "Business Entity",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Effective Tool Adoption requires planning, execution, stakeholder engagement, and alignment with organizational goals. Our expertise helps in implementation of right set of Tools and Applications and more importantly Adoption for the relevant processes and ensures smooth operations."
    },
    {
      image: icon,
      title: "CORPORATE EXPERIENCE CENTER",
      description: "(Real Problem & Real Team)",
      category: "University & College",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Immersive Corporate Experience Centre highlights real-time problems, inspires innovation, fosters collaboration, and accelerates decision-making for successful problem-solving and leverage our rich expertise in building Experience Centres to solve Real Problems in Real Time."
    },
    {
      image: icon,
      title: "GROWTH STRATEGIES",
      description: "(Scope wise - B.O.T)",
      category: "Start Ups & MSMEs",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Growth Strategies are essential for expansion, revenue increase, and market reach. We guide you in choosing the right approach, including organic growth, strategic growth, diversification, M&A, BOT, and more."
    },
    {
      image: icon,
      title: "DATA INTELLIGENCE",
      description: "(Digital Dashboards)",
      category: "Business Entity",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Data Intelligence unlocks insights about data assets—who, what, where, and when. Our expertise enables efficient decision-making and empowers leaders with quality digital dashboards."
    },
    {
      image: icon,
      title: "BID & PROPOSAL MANAGEMENT",
      description: "(Creatives & Graphics)",
      category: "MSME & Start Ups",
      buttonText: "Enquire",
      buttonKnowMore: "Know More",
      content: "Bid & Proposal Management is a systematic process for creating, submitting, and tracking sales proposals. Our expertise combines Creatives and Graphics, Strategy and effective communication to win business opportunities."
    },
  ];


  return (
    <>
      <div className="container product-section-home">
        <div className="description-box gallery-box mb-35 wow fadeInUp">
          <div className="title">
            <h2>What We Do ?</h2>
          </div>
          <Slider {...sliderSettings} className="gallery-slider mb-20" ref={(ref) => (sliderRef = ref)}>
            {sliderItems.map((item, index) => (
              <div key={index} className="single-gallery-img">
                <center>
                  <div
                    className={`card-contents ${
                      index % 2 === 0 ? "blue-bg" : "orange-bg"
                    }`}
                  >
                    <img src={item.image} alt="Gallery" />
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    <hr />
                    <h3>{item.category}</h3>

                    <div className="d-flex justify-content-center align-items-center">
                      <button className="form-button mx-2" onClick={() => openPopup(item)}>
                        {item.buttonKnowMore}
                      </button>
                      <Link to="/contact">
                        <button className="form-button">
                          {item.buttonText}
                        </button>
                      </Link>
                    </div>
                  </div>
                </center>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {showPopup && (
        <div className="custom-popup">
          <button className="close-icon" onClick={closePopup}>
            X
          </button>
          {selectedItem && (
            <div className="popup-content">
              <h2>{selectedItem.title}</h2>
              <hr />
              <p>{selectedItem.content}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Products;
