import React from 'react'
import FAQ from '../Components/Home/Faq'
import Announcements from '../Components/Home/Announcements'

const KnowMore = () => {
  return (
    <>
    <div className="container">
        <FAQ />
        <Announcements />
    </div>
    </>
  )
}

export default KnowMore