import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

const DATAFAQ = () => {
  const faqsData = [
    {
      title: "Corporate Experience Center",
      content:
        "Dedicated facility for immersive corporate experiences, fostering hands-on learning and industry insights. Creates an environment for practical learning.",
    },
    {
      title: "Industry Guest Lectures",
      content:
        "Invites experts from diverse industries for valuable insights. Shares experiences with participants for real-world perspectives. Enhances learning through industry-specific knowledge.",
    },
    {
      title: "Leadership Strategy Workshop",
      content:
        "Engages participants in sessions for developing leadership skills. Focuses on strategic thinking and decision-making capabilities. Aims to cultivate effective leaders within the organization.",
    },
    {
      title: "Essential Skills Training",
      content:
        "Offers targeted programs to enhance essential skills. Covers communication, teamwork, problem-solving, and time management. Ensures individuals are well-equipped for their roles.",
    },
    {
      title: "Design Thinking Workshops",
      content:
        "Conducts interactive workshops to instill a design thinking mindset. Fosters creativity, innovation, and user-centric problem-solving. Encourages a holistic approach to addressing challenges.",
    },
    {
      title: "NAAC & NBA Enablement",
      content:
        "Provides assistance and support for accreditation from NAAC and NBA. Guides institutions in compliance matters. Aims to enhance the overall quality of educational institutions.",
    },
    {
      title: "PMO Services",
      content:
        "Offers Project Management Office services with structured frameworks. Provides methodologies and support for effective project management. Ensures projects are executed efficiently and meet objectives.",
    },
    {
      title: "Business Process Optimization",
      content:
        "Systematically reviews and enhances business processes. Maximizes efficiency, reduces costs, and improves overall performance. Strives for continuous improvement in organizational processes.",
    },
    {
      title: "Performance Management",
      content:
        "Provides strategies and tools for managing and improving employee performance. Aligns individual goals with organizational objectives. Enhances overall productivity through effective performance management.",
    },
    {
      title: "Process Maturity Modeling",
      content:
        "Implements models to assess and enhance organizational process maturity. Ensures continuous improvement in process efficiency. Establishes a framework for organizational growth and development.",
    },
    {
      title: "Decision Making Models",
      content:
        "Offers frameworks and methodologies to enhance decision-making processes. Facilitates informed and strategic decision-making within the organization. Aims to improve the overall decision-making efficiency.",
    },
    {
      title: "Maturity Measurements & Models",
      content:
        "Establishes systems for measuring and improving organizational maturity. Utilizes models across various domains for comprehensive assessment. Focuses on achieving maturity in organizational processes.",
    },
    {
      title: "Change Adoption Workshop",
      content:
        "Conducts workshops focused on facilitating smooth transitions during organizational changes. Encourages employee buy-in and adaptation to change. Strives for a positive organizational change culture.",
    },
    {
      title: "Operational Excellence",
      content:
        "Initiates and sustains superior performance in operational and business processes. Aims for efficiency, effectiveness, and continuous improvement. Focuses on achieving excellence in day-to-day operations.",
    },
    {
      title: "Metrics Management & Visualization",
      content:
        "Establishes systems for effective measurement, tracking, and visualization of key performance metrics. Enhances data-driven decision-making within the organization. Provides a visual representation of key performance indicators.",
    },
    {
      title: "Data Analytics & Intelligence",
      content:
        "Integrates data analytics and business intelligence for actionable insights. Extracts meaningful information to inform decision-making. Utilizes data intelligence for strategic planning and optimization.",
    },
    {
      title: "Quality Assurance",
      content:
        "Ensures and maintains high-quality standards in products, services, and processes. Implements systematic quality assurance measures. Strives for excellence in delivering quality to stakeholders.",
    },
    {
      title: "Risk Management",
      content:
        "Develops strategies and practices to identify, assess, and mitigate potential risks. Ensures proactive risk management for organizational resilience. Aims to safeguard the organization against potential threats.",
    },
    {
      title: "Training Management",
      content:
        "Efficiently plans, executes, and tracks training programs. Enhances employee skills and competencies through targeted training. Ensures alignment of training programs with organizational goals.",
    },
    {
      title: "Digitization",
      content:
        "Adopts digital technologies and processes to streamline operations. Enhances overall organizational efficiency through digitization. Embraces technological advancements for improved productivity.",
    },
    {
      title: "Technology, Tools & Accelerators",
      content:
        "Integrates cutting-edge technologies, tools, and accelerators. Drives innovation and productivity in organizational processes. Leverages technology for sustained organizational growth.",
    },
  ];

  const categories = ["All", "Corporate", "Training", "Management", "Technology"];

  const [activeCategory, setActiveCategory] = useState("All");
  const [activeKey, setActiveKey] = useState(null);

  const filterDataByCategory = (category) => {
    if (category === "All") {
      return faqsData;
    } else {
      return faqsData.filter((faq) => faq.title.toLowerCase().includes(category.toLowerCase()));
    }
  };

  const handleAccordionClick = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };
  

  return (
    <>
      <div className="tab-container-what-we-do">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={activeCategory === category ? "active" : ""}
          >
            {category}
          </button>
        ))}
      </div>
      <Accordion activeKey={activeKey} onSelect={(eventKey) => handleAccordionClick(eventKey)}>
        {filterDataByCategory(activeCategory).map((faq, i) => (
          <div key={i} className="accordion-card mb-15">
            <Accordion.Item eventKey={`event-${i}`} style={{ cursor: "pointer" }}>
              <Accordion.Header>{faq.title}</Accordion.Header>
              <Accordion.Body>
                {Array.isArray(faq.content) ? (
                  <ul>
                    {faq.content.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p>{faq.content}</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </div>
        ))}
      </Accordion>
    </>
  );
};
export default DATAFAQ;
