import React, { useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import burger from "../Assets/Icons/menu.png";
import close from "../Assets/Icons/close.png";
import logo from "../Assets/Logo/logo.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuStyles = {
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      top: 0,
      right: 0,
    },
    bmMenu: {
      background: "#007eff",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmBurgerBars: {
      background: "#fff",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
      right: "24px",
      top: "24px",
    },
    bmItemList: {
      color: "#b8b7ad",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  const handleMenuStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <Menu
          width={"25%"}
          customBurgerIcon={
            <img
              className="menu-burger"
              src={burger}
              alt="burger"
              onClick={toggleMenu}
            />
          }
          customCrossIcon={
            <img
              className="menu-close"
              src={close}
              alt="close"
              onClick={toggleMenu}
            />
          }
          right
          isOpen={isMenuOpen}
          onStateChange={handleMenuStateChange}
          styles={menuStyles}
          noOverlay
        >
          <Link className="menu-item" to="/about" onClick={closeMenu}>
            About Us
          </Link>
          <Link className="menu-item" to="/whatwedo" onClick={closeMenu}>
            What We Do
          </Link>
          <Link className="menu-item" to="/contact" onClick={closeMenu}>
            Contact Us
          </Link>
          <Link className="menu-item" to="/partner" onClick={closeMenu}>
            <button className="header-button my-2">For Partners</button>
          </Link>
          <Link className="menu-item" to="/client" onClick={closeMenu}>
            <button className="header-button-orange my-2">For Clients</button>
          </Link>
          <Link className="menu-item" to="/knowmore" onClick={closeMenu}>
            Know More
          </Link>
        </Menu>
      </nav>
    </header>
  );
};

export default Header;
