import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [formErrors, setFormErrors] = useState({ email: false });

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "checkbox") {
      setIsChecked(checked);
    } else {
      setEmail(value);
      setFormErrors((prevFormErrors) => ({ ...prevFormErrors, email: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFormErrors = { email: email.trim() === "" || !isChecked };
    setFormErrors(newFormErrors);

    if (Object.values(newFormErrors).some((error) => error)) {
      return;
    }

    try {
      const templateParams = {
        user_id: "-3D_um43d5oi3zCTk",
        service_id: "service_1a1m5yk",
        template_id: "template_gdjqbo7",
        email,
      };

      const response = await emailjs.send(
        "newsletter",
        "Newsletter",
        templateParams
      );

      if (response.status === 200) {
        alert("Subscription successful");
      } else {
        alert("Failed to subscribe. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending subscription data:", error);
      alert("Failed to subscribe. Please try again later.");
    }
  };

  return (
    <section className="newsletter-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-12">
            <div className="section-title section-title-white wow fadeInLeft">
              <h2>Subscribe Our Newsletter to Get More Updates</h2>
            </div>
          </div>
          <div className="col-xl-7 col-lg-12">
            <div className="newsletter-from wow fadeInRight">
              <form onSubmit={handleSubmit}>
                <div
                  className={`form_group ${
                    formErrors.email ? "has-error" : ""
                  }`}
                >
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form_checkbox">
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="check2"
                    checked={isChecked}
                    onChange={handleInputChange}
                  />
                  <label className="check2" htmlFor="check2">
                    <span>
                      I agree to the <Link to="/privacy"> Privacy Policy</Link>
                    </span>
                  </label>
                </div>
                <button className="form-button" type="submit">
                  Subscribe Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
