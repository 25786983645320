import React from "react";

const Policy = () => {
  const privacySections = [
    {
      title: "Data Collection and Storage",
      content:
        "We collect personal information (e.g., name, email) and usage data (e.g., IP, browser, device) to provide and improve our services. Your data is used for services, communication, and occasional updates. We do not sell your information.",
    },
    {
      title: "Data Security",
      content:
        "We protect your information from unauthorized access or disclosure. While we use industry-standard security practices, we cannot guarantee complete security. Internet communications always carry inherent risks.",
    },
    {
      title: "Cookies",
      content:
        "Cookies are small data files that help us track your interactions with our website. Cookies are used to enhance your experience, improve our services, and provide us with valuable insights into how users engage with our platform.",
    },
    {
      title: "Your Data, Your Choice",
      content:
        "You have control over your data. You can access, correct, or delete your personal information stored with us. Additionally, you have the option to opt out of receiving promotional communications at any time.",
    },
  ];

  return (
    <>
      <section className="mt-5">
        <div className="container p-5">
          <div className="card-privacy">
            <div className="privacy-page">
              <div className="privacy-header">
                <h3>Privacy Policy</h3>
                <hr />
                <p>Last updated: 2024</p>
              </div>

              <div className="privacy-content my-3">
                {privacySections.map((section, index) => (
                  <div key={index}>
                    <h6 className="my-2">
                      {index + 1}. {section.title}
                    </h6>
                    <p>{section.content}</p>
                    <hr />
                  </div>
                ))}
              </div>

              <div className="privacy-contact">
                <p>
                  For any questions or concerns about this Privacy Policy or the
                  handling of your personal data, please feel free to contact us
                  at <a href="mailto:coe@formatiqs.in">coe@formatiqs.in</a>
                </p>
              </div>

              <div className="privacy-thank-you">
                <p>
                  Thank you for entrusting FormatiQs with your data. Your
                  privacy is important to us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Policy;
