import React from "react";
import DataFaq from "./DataFaq";
import "animate.css";
import { useInView } from "react-intersection-observer";

const FAQ = () => {

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <section ref={ref}
        className={`${inView ? "animate__animated animate__fadeInUp" : ""}`}>
        <div className="container features-section">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
            <div className="title mb-5">
          <h2>
            FormatiQs Holistic Approach
          </h2>
        </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="faq-one_content-box mb-50">
                <div
                  className="faq-accordion-two mb-50 wow fadeInUp"
                  id="accordionOne"
                >
                  <DataFaq />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
