import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import border from "../../Assets/Border/border.png";
import "animate.css";
import video from "../../Assets/Video/video-1.mp4";
import { TypeAnimation } from "react-type-animation";

const Hero = () => {
  const navigate = useNavigate();
  const [currentText, setCurrentText] = useState("");
  const [description, setDescription] = useState("");

  const redirectPage = () => {
    navigate("/contact");
  };

  useEffect(() => {
    const sequence = [
      "Business Optimization Mechanics",
      1000,
      "Business Consulting Coach",
      1000,
      "Change Enablement Leader",
      1000,
    ];

    let currentIndex = 0;

    const interval = setInterval(() => {
      const currentElement = sequence[currentIndex];
      if (typeof currentElement === "string") {
        setCurrentText(currentElement);
        setDescription(getDescriptionForText(currentElement));
      }
      currentIndex++;
      if (currentIndex >= sequence.length) {
        currentIndex = 0;
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="hero animate__animated animate__fadeInDown">
      <video
        autoPlay
        loop
        muted
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '-1',
        }}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="hero-content">
        <div className="content">
          <TypeAnimation
            sequence={[
              "Business Optimization Mechanics",
              5000,
              "Business Consulting Coach",
              5000,
              "Change Enablement Leader",
              5000,
            ]}
            speed={5000 / (75 + 2)}
            style={{ color: "#f1e8d1", fontSize: "3.5rem", fontWeight: "900" }}
            repeat={Infinity}
          />

          <p style={{ color: "#f1e8d1" }}>{description}</p>

          <button className="hero-button" onClick={redirectPage}>
            Let's Connect
          </button>
        </div>
      </div>
    </div>
  );
};

const getDescriptionForText = (text) => {
  switch (text) {
    case "Business Optimization Mechanics":
      return "For MSMEs, educational institutions, family run businesses, startups & scaleups";
    case "Business Consulting Coach":
      return "For Industry SMEs, Institute, professional. startups leadership & other advisors";
    case "Change Enablement Leader":
      return "For Individuals, cross teams, function, departments & other scoped groups";
    default:
      return "";
  }
};

export default Hero;
